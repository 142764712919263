<template>
  <BasePageSection
    :bg-image="heroBgImg"
    class="home-hero"
    has-overlay
    py-5
    full-bleed-bg
  >
    <VLayout v-if="theLocation" row wrap>
      <VFlex class="text-xs-left">
        <VCard
          :class="['hero-cta elevation-0 py-4', `px-${heroPaddingAmount}`]"
        >
          <VCardText :class="`pb-${$mq === 'xs' ? 2 : 5} px-0`">
            <div class="hero-text white--text"
              >{{ getContactDetail('street_1') }}<br />
              {{ `${getContactDetail('city')}, ${getContactDetail('state')}`
              }}<br />{{ theLocation.contactinfo.phone_number }}</div
            >
            <div
              v-if="theLocation.hours_for_humans.length"
              class="hero-text hero-hours white--text"
              >{{ theLocation.hours_for_humans }}</div
            >
          </VCardText>
          <!-- TODO (ESS) : Make this single loc dynamic -->
          <BaseButton
            to="/locations/blue-oak-bbq"
            class="mr-4 ml-0"
            :large="!['sm', 'md'].includes($mq)"
            color="#f74b8a"
          >
            View Menu
          </BaseButton>
          <BaseButtonOutline
            :href="
              `https://www.google.com/maps/dir/?api=1&destination=${getContactDetail(
                'street_1'
              )} ${getContactDetail('city')},${getContactDetail('state')}`
            "
            class="ml-0"
            target="_blank"
            :large="!['sm', 'md'].includes($mq)"
          >
            Get Directions
          </BaseButtonOutline>
        </VCard>
      </VFlex>
    </VLayout>
  </BasePageSection>
</template>

<script>
import { mapGetters } from 'vuex'
import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'BlueOakHomeHero',
  data() {
    return {}
  },
  computed: {
    ...mapGetters('site', ['getSiteTitle']),
    ...mapGetters('locations', ['getLocationBySlug']),
    ...mapGetters('pages', ['getPageContent']),
    heroPaddingAmount() {
      switch (this.$mq) {
        case 'xs':
          return '0'
        case 'sm':
          return '2'
        case 'md':
          return '3'
        default:
          return '5'
      }
    }, // heroPaddingAmount
    theLocation() {
      // TODO (ESS) : make this single loc slug dynamic
      return this.getLocationBySlug('blue-oak-bbq')
    }, // theLocation
    heroBgImg() {
      return (
        this.getPageContent('hero_bg_img') ||
        'https://res.cloudinary.com/resto/image/upload/b_rgb:1A1D48,c_scale,o_90,w_1600/v1588099784/users/blueoakbbq/fried-chicke-sando.jpg'
      )
    },
  },
  methods: {
    formatPhone,
    getContactDetail(key) {
      return getContactDetail(this.theLocation, key)
    }, // getContactDetail
  },
}
</script>

<style lang="scss">
@import '@design';

.home-hero {
  position: relative;
}

.v-card.hero-cta {
  background-color: transparent;
}
</style>
